import { createSlice } from '@reduxjs/toolkit'
import { mapSettings } from '../utils/enums/mapSettings'

const initialState = {
  adSpacesFilter: {
    availability_start_date: null,
    availability_end_date: null,
    format_ids: [],
    provider_ids: [],
    locality_ids: [],
    region_ids: [],
    query_filter: '',
    sku_filter: '',
    show_unavailable: false
  },
  bounds: {},
  oldBounds: {},
  zoom: mapSettings.defaultZoom
}

export const mapFilterSlice = createSlice({
  name: 'mapFilter',
  initialState,
  reducers: {
    setadSpacesFilter: (state, action) => {
      state.adSpacesFilter = action.payload
    },
    setBoundsFilter: (state, action) => {
      state.oldBounds = action.payload
      state.bounds = action.payload
    },
    setZoomFilter: (state, action) => {
      state.zoom = action.payload
    },
    clearMapFilter: (state) => {
      state.adSpacesFilter = initialState.adSpacesFilter
    }
  }
})

export const { setadSpacesFilter, clearMapFilter, setBoundsFilter, setOldBoundsFilter, setZoomFilter } = mapFilterSlice.actions
export default mapFilterSlice.reducer

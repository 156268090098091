import { MarkerF } from '@react-google-maps/api'

/* methods for labels */
const getStyledLabel = (cluster) => {
  const labelText = cluster.num_points > 1 ? cluster.num_points : null
  if (!labelText) return null

  return {
    text: labelText?.toString(),
    color: 'white',
    fontWeight: 'bold'
  }
}

/* methods for icons */
const iconUrl = (cluster) => {
  const marker_uuid = cluster.num_points === 1 ? cluster.item_ids[0] : null
  const url = `${process.env.PUBLIC_URL}/assets/markers/${cluster.icon ? cluster.icon : 'marker-letrero-publicitario.png'}`

  if (marker_uuid) {
    return url
  }

  let size
  if (cluster.num_points < 10) {
    size = 'm1'
  } else if (cluster.num_points < 100) {
    size = 'm2'
  } else if (cluster.num_points < 500) {
    size = 'm3'
  } else if (cluster.num_points < 1000) {
    size = 'm4'
  } else {
    size = 'm5'
  }

  return `${process.env.PUBLIC_URL}/assets/cluster-icons/${size}.png`
}

const markerScaledSize = (cluster) => {
  const clusterSize = cluster.num_points
  if (clusterSize === 1) return new window.google.maps.Size(30, 30)
  const size = clusterSize < 100 ? 50 : clusterSize < 1000 ? 70 : 80

  return new window.google.maps.Size(size, size)
}

const getIconOptions = (cluster) => {
  if (!iconUrl(cluster)) return null

  const iconOptions = {}
  iconOptions.icon = {
    url: iconUrl(cluster),
    scaledSize: markerScaledSize(cluster)
  }
  return iconOptions
}

const isBouncing = (activeMarkerUuid, cluster) => {
  if (activeMarkerUuid.uuid === null) return false
  if (activeMarkerUuid.active === false) return false

  return cluster.item_ids.includes(activeMarkerUuid.uuid)
}

/* methods for markers */
const renderMarker = (cluster, index, activeMarkerUuid, handleMarkerClick) => {
  const animation = isBouncing(activeMarkerUuid, cluster) ? window.google.maps.Animation.BOUNCE : null
  return (
    <MarkerF
      key={`${index}-${cluster.centroid}-${cluster.num_points}`}
      position={cluster}
      label={getStyledLabel(cluster)}
      animation={animation}
      options={getIconOptions(cluster)}
      onClick={() => {
        handleMarkerClick(cluster)
      }}></MarkerF>
  )
}

export const renderMarkerClusters = (markers, activeMarkerUuid, handleMarkerClick) => {
  if (!markers || markers.length === 0) {
    return null
  }

  return markers?.map((marker, index) => renderMarker(marker, index, activeMarkerUuid, handleMarkerClick))
}
